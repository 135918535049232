import React, { useState } from "react";
import "./AccountDelete.css";

const AccountDelete = () => {
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:tudballreuben@gmail.com?subject=Account Deletion Request&body=User Email: ${email}%0D%0AReason for Deletion: ${reason}`;
    window.location.href = mailtoLink;
  };

  return (
    <custom-deletion-form>
      <h2 className="deletion-title">Flush - Request Account Deletion</h2>
      <p className="deletion-description">
        This page is for requesting the deletion of your account and associated data.
      </p>

      <section className="steps-section">
        <h3>Steps to Request Account Deletion</h3>
        <ol>
          <li>Enter your registered email address in the form below.</li>
          <li>Provide a reason for deletion (optional).</li>
          <li>Click "Submit Request" to send your request via email.</li>
          <li>You will receive a confirmation once your request is processed.</li>
        </ol>
      </section>

      <section className="data-section">
        <h3>Data Deleted</h3>
        <ul>
          <li>Account details, reviews, comments, and help tickets will be permanently deleted.</li>
        </ul>
      </section>

      <form className="deletion-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label" htmlFor="email">
            Your Email Address:
          </label>
          <input
            className="form-input"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="reason">
            Reason for Deletion (Optional):
          </label>
          <textarea
            className="form-textarea"
            id="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          ></textarea>
        </div>
        <button className="form-button" type="submit">
          Submit Request
        </button>
      </form>
    </custom-deletion-form>
  );
};

export default AccountDelete;
