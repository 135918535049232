import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy for Flush</h1>
      <p><strong>Effective Date:</strong> 12/24/2024</p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Flush is committed to protecting the privacy of its users. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our mobile application, Flush, and its sister web application for facilities management. By using our services, you agree to the terms of this policy.
        </p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <h3>a. User-Provided Information</h3>
        <ul>
          <li>Account details: Name, email address, and other profile details during sign-up.</li>
          <li>Reviews and ratings submitted for restrooms.</li>
          <li>Help tickets submitted, including descriptions of issues.</li>
        </ul>

        <h3>b. Automatically Collected Information</h3>
        <ul>
          <li>Location data for restroom discovery and tagging.</li>
          <li>Device information such as operating system and browser type.</li>
          <li>Interaction logs with app features (e.g., QR code scanning).</li>
        </ul>

        <h3>c. Data From Third Parties</h3>
        <ul>
          <li>Authentication details via third-party services (e.g., Google Sign-In).</li>
          <li>Geolocation data via Google Maps and GeoLocator API.</li>
        </ul>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <ul>
          <li>Enable core functionalities, such as restroom discovery and review submission.</li>
          <li>Process and prioritize help tickets for facilities management.</li>
          <li>Improve app functionality through analytics, including heatmaps and sentiment analysis.</li>
          <li>Send notifications related to your account and app usage.</li>
        </ul>
      </section>

      <section>
        <h2>4. Data Sharing and Disclosure</h2>
        <p>
          We do not sell or rent your personal information. However, we may share your data:
        </p>
        <ul>
          <li>With facilities management for restroom maintenance.</li>
          <li>With service providers (e.g., Firebase, Google Maps) to enable app functionalities.</li>
          <li>As required by law or to protect legal rights.</li>
        </ul>
      </section>

      <section>
        <h2>5. Data Storage and Security</h2>
        <p>
          Your data is stored securely in Firebase. We use encryption and access controls to protect your data. However, no method of transmission over the Internet or electronic storage is 100% secure.
        </p>
      </section>

      <section>
        <h2>6. User Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access and review your data.</li>
          <li>Update or delete your profile information.</li>
          <li>Withdraw consent for data processing (may limit app functionality).</li>
        </ul>
      </section>

      <section>
        <h2>7. Cookies and Tracking Technologies</h2>
        <p>
          Flush does not use cookies but may leverage similar technologies for location-based services and QR code scanning.
        </p>
      </section>

      <section>
        <h2>8. Third-Party Links</h2>
        <p>
          Flush may contain links to third-party websites or services. We are not responsible for their privacy practices.
        </p>
      </section>

      <section>
        <h2>9. Changes to This Policy</h2>
        <p>
          We may update this policy periodically. Changes will be communicated via the app and updated on this page.
        </p>
      </section>

      <section>
        <h2>10. Contact Us</h2>
        <p>
          For questions or concerns, please contact me at:
          <br />
          <strong>tudballreuben@gmail.com</strong>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
